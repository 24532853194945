import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography, createTheme } from '@mui/material';
import './App.css';
import { ThemeContext } from '@emotion/react';
import React from 'react';

const HOST_URL = window?.regioschau2024?.host || 'kunde.bytt.app' 

const THEME = createTheme({
	"palette": {
		"primary": {
			"main": "#24338b"
		},
		"secondary": {
			"main": "#38aa34"
		}
	}
})

function App() {
	const [open, setOpen] = React.useState(false);

  return (
	<ThemeContext.Provider value={THEME}>
    	<div className="regioschau2024">
			<SpeedDial
				ariaLabel="Regioschau Kraichgau 2024"
				sx={{ position: 'fixed', bottom: 16, right: 16 }}
				openIcon={<img src={`${window.location.protocol}//${HOST_URL}/avatar-regioschau-offen.png`} style={{
					width: '100%',
					height: '100%',
					objectFit: 'contain',
					objectPosition: 'center'
				}}/>}
				icon={<img src={`${window.location.protocol}//${HOST_URL}/avatar-regioschau.png`} style={{
					width: '100%',
					height: '100%',
					objectFit: 'contain',
					objectPosition: 'center'
				}}/>}
			>
			<SpeedDialAction
				onClick={() => {window.open('https://www.regioschau-kraichgau.de/?utm_campaign=badge&utm_medium=EintrittFrei', '_blank')}}
				sx={{
					backgroundColor: '#24338b',
					'&:hover': {
						backgroundColor: '#38aa34'
					}
				}}
				icon={
					<SpeedDialIcon icon={<img src="https://www.regioschau-kraichgau.de/wp-content/uploads/2017/10/eintritt.svg" style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						objectPosition: 'center'
						}}/>}/>
					}
				tooltipTitle="Kostenloser Eintritt"
				/>
			
			<SpeedDialAction
				onClick={() => {window.open('https://www.regioschau-kraichgau.de/ausstellerliste/?utm_campaign=badge&utm_medium=Ausstellerliste', '_blank')}}
				sx={{
					backgroundColor: '#24338b',
					'&:hover': {
						backgroundColor: '#38aa34'
					}				}}
				icon={
					<SpeedDialIcon icon={<img src="https://www.regioschau-kraichgau.de/wp-content/uploads/2017/10/Stand.svg" style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						objectPosition: 'center'
					}}/>}/>
				}
				tooltipTitle="60 Aussteller"
				/>
			
			<SpeedDialAction
				onClick={() => {window.open('https://www.regioschau-kraichgau.de/?utm_campaign=badge&utm_medium=Rahmenprogramm', '_blank')}}
				sx={{
					backgroundColor: '#24338b',
					'&:hover': {
						backgroundColor: '#38aa34'
					}
				}}
				icon={
					<SpeedDialIcon icon={<img src="https://www.regioschau-kraichgau.de/wp-content/uploads/2017/10/luftballons.svg" style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						objectPosition: 'center'
					}}/>}/>
				}
				tooltipTitle="tolles Rahmenprogramm"
				/>
			
			<SpeedDialAction
				onClick={() => {window.open('https://www.regioschau-kraichgau.de/?utm_campaign=badge&utm_medium=Datum', '_blank')}}
				sx={{
					backgroundColor: '#24338b',
					'&:hover': {
						backgroundColor: '#38aa34'
					}
				}}
				icon={
					<SpeedDialIcon icon={<img src={`${window.location.protocol}//${HOST_URL}/save-the-date.png`} style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						objectPosition: 'center'
					}}/>}/>
				}
				tooltipTitle="23. + 24. März 2024"
				/>
			</SpeedDial>
    	</div>
	</ThemeContext.Provider>
  );
}

export default App;
