import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const regioschauElement = document.createElement('div');
regioschauElement.id = 'regioschau-2024-container';
document.body.appendChild(regioschauElement);

const regioschauRoot = ReactDOM.createRoot(document.getElementById('regioschau-2024-container'));
regioschauRoot.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
